











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab6Q5',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'Catalyst', value: 'catalyst'},
        {text: 'Electrophile', value: 'electrophile'},
        {text: 'Nucleophile', value: 'nucleophile'},
        {text: 'Solvent', value: 'solvent'},
        {text: 'To keep the reaction mixture cold', value: 'keepCold'},
        {text: 'To quench the Grignard reaction', value: 'quench'},
      ],
      optionsFr: [
        {text: 'Catalyseur', value: 'catalyst'},
        {text: 'Électrophile', value: 'electrophile'},
        {text: 'Nucléophile', value: 'nucleophile'},
        {text: 'Solvant', value: 'solvent'},
        {text: 'De garder le mélange réactionnel froid', value: 'keepCold'},
        {text: 'De neutraliser la réaction de Grignard', value: 'quench'},
      ],
    };
  },
};
